import request from '@/plugins/axios'
// 添加词、组
export function setChannel(data) {
  return request({
    url: '/channel',
    method: 'post',
    data
  })
}
// 列表数据
export function channelPage(page, limit, data) {
  return request({
    url: `/channel/${page}/${limit}`,
    method: 'post',
    data: data
  })
}
// 删除
export function delChannel(id) {
  return request({
    url: `/channel/${id}`,
    method: 'delete'
  })
}

// 视频列表

export function videoList(page, limit, data) {
  return request({
    url: `/video/${page}/${limit}`,
    method: 'post',
    data: data
  })
}
// 视频详情
export function videoDetail(videoId) {
  return request({
    url: `/video/${videoId}`,
    method: 'get'
  })
}
// 视频评论
export function videoComment(page, limit, videoId) {
  return request({
    url: `/comment/${page}/${limit}/${videoId}`,
    method: 'get'
  })
}

// 重载评论
export function commentOverload(videoId) {
  return request({
    url: `/comment/overload/${videoId}`,
    method: 'post'
  })
}

// 分页视频评论
export function commentPage(page, limit, data) {
  return request({
    url: `/comment/${page}/${limit}`,
    method: 'post',
    data
  })
}

// 收藏
export function collect(data) {
  return request({
    url: `/collect`,
    method: 'post',
    data: data
  })
}
// 取消收藏

export function clearCollect(id) {
  return request({
    url: `/collect/${id}`,
    method: 'delete'
  })
}

// 收藏列表分页
export function collectPage(page, limit, data) {
  return request({
    url: `/collect/${page}/${limit}`,
    method: 'post',
    data: data
  })
}

// 阅读日志
export function logRead() {
  return request({
    url: `/log/search`,
    method: 'get'
  })
}

//删除 阅读日志
export function dellogRead(id) {
  return request({
    url: `/log/search/${id}`,
    method: 'delete'
  })
}

<template>
  <div class="channel-words">
    <div class="channel-words-head">
      <el-form :inline="true" ref="form">
        <el-form-item>
          <el-input
            v-model.trim="searchForm.channelUrl"
            clearable
            style="width: 622px"
            placeholder="请输入链接,例如：https://www.youtube.com/channel/UCwUFx_z61wqMV8zTUVDNV1w"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-top" type="primary" @click="addPlan">
            立即提交
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-popover placement="bottom" trigger="hover">
            <div>
              <p>
                【1】提交Youtube的频道链接后我们后台程序将会对此链接下的所有视频和评论进行采集，并提供视频播放，和多维度的全文检索功能
              </p>
              <p>
                【2】状态为“成功”后，“操作”中会有“视频列表”按钮,点击“视频列表”按钮，即可查看此条链接下是的所有视频，点击视频可进入视频详情页
              </p>
              <p>
                【3】视频详情页中可以点击“重载评论”来获取最新评论,“收藏”，可以将将视频收藏到“我的收藏”中，以防视想再看这个视频的时候找不到
              </p>
            </div>
            <span slot="reference" class="head-info">
              <img src="@/assets/imgs/help.png" alt="" />
              <span>使用说明</span>
            </span>
          </el-popover>
        </el-form-item>
      </el-form>
      <div class="select-box">
        <el-select
          v-model="statusValue"
          placeholder="根据状态查询数据"
          @change="selectStatus"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="channel-pagination">共查询到数据{{ total }}条</div>
    <div
      class="channel-words-content"
      v-loading="loading"
      v-infinite-scroll="getList"
      :infinite-scroll-disabled="scrollDisabled"
      :infinite-scroll-distance="10"
    >
      <div class="channel-words-content-child">
        <div class="content-item" v-for="(item, i) in listData" :key="i">
          <div class="content-item-top">
            <div class="item-top-left">
              <div>
                <img
                  :src="
                    item.channel
                      ? item.channel.avatarThumbnails
                      : require('@/assets/imgs/i12.png')
                  "
                  alt=""
                  :onerror="noPic"
                />
              </div>
              <div class="item-name-box">
                <div class="item-name">
                  {{ item.channel ? item.channel.title : '' }}
                </div>
                <div class="item-register">
                  <span>
                    {{ item.channel ? item.channel.subscriberCount : '' }}
                  </span>
                  <span class="modification"></span>
                  <span>
                    {{ item.channel ? item.channel.joinedDate + '注册' : '' }}
                  </span>
                </div>
              </div>
            </div>
            <div class="item-top-right">
              <el-popover
                placement="bottom"
                trigger="hover"
                popper-class="keywords-popover"
              >
                <div>
                  <div>
                    <el-button
                      class="el-icon-video-play"
                      size="mini"
                      type="text"
                      @click="getVideo(item)"
                      v-if="item.channelId"
                    >
                      视频列表
                    </el-button>
                  </div>
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      class="el-icon-delete"
                      style="color: red"
                      @click="delData(item)"
                    >
                      删除
                    </el-button>
                  </div>
                </div>
                <span
                  slot="reference"
                  style="margin-top: 20px !important"
                  class="left-list-info"
                >
                  <img src="@/assets/imgs/i7.png" alt="" />
                </span>
              </el-popover>
            </div>
          </div>

          <div class="content-item-key">
            <div class="content-item-channel-top">
              <img src="@/assets/imgs/i13.png" alt="" />
              <span>链接地址</span>
            </div>
            <div class="content-item-channel-text">
              <span class="website-text">
                {{ item.channelUrl }}
                <img
                  title="点击复制链接"
                  src="@/assets/imgs/i14.png"
                  @click="copyClick(item.channelUrl)"
                  alt=""
                />
              </span>
            </div>
          </div>
          <div
            v-if="item.status !== 0"
            class="content-item-key"
            style="margin-top:10px"
          >
            <div class="content-item-channel-top">
              <img src="@/assets/imgs/i15.png" alt="" />
              <span>群组ID</span>
            </div>
            <div class="content-item-channel-text content-item-channel-text2">
              {{ item.channelId }}
            </div>
          </div>
          <div v-if="item.status !== 0" class="content-item-channel-type">
            <div>
              <img v-if="item.status == 1" src="@/assets/imgs/i16.png" alt="" />
              <img
                v-else-if="item.status == 2"
                src="@/assets/imgs/i17.png"
                alt=""
              />
              <img
                v-else-if="item.status == 0"
                src="@/assets/imgs/i18.png"
                alt=""
              />
            </div>
            <div
              class="type-info"
              :title="item.channel ? item.channel.description : ''"
            >
              <span v-if="item.status == 2">{{ item.reason }}</span>
              <span>{{ item.channel ? item.channel.description : '' }}</span>
            </div>
          </div>
          <div v-if="item.status !== 0" class="content-item-time">
            更新时间：{{ item.updateTime }}
          </div>
          <!-- 处理中 -->
          <div v-if="item.status == 0" class="channel-status2">
            <img src="@/assets/imgs/i19.png" alt="" />
            <div class="status2-text">数据处理中，请稍后刷新查看...</div>
          </div>
        </div>
      </div>
      <li v-if="listData.length > 0">{{ nomoreText }}</li>
    </div>

    <noData v-if="listData.length === 0" />
  </div>
</template>

<script>
import { channelPage, setChannel, delChannel } from '@/api/channel.js'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      loading: false,
      searchForm: {
        channelUrl: ''
      },
      listData: [],
      // 当前页
      current: 1,
      // 每页记录数
      limit: 40,
      // 总记录数
      total: 0,
      dialogVisible: false,
      submitForm: { groupName: '', searchKey: '' },
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '处理中'
        },
        {
          value: 1,
          label: '处理成功'
        },
        {
          value: 2,
          label: '处理失败'
        }
      ],
      statusValue: '',
      nomoreText: '加载中...',
      scrollDisabled: false,
      dataIndex: null
    }
  },
  created() {
    // this.getList()
  },
  methods: {
    getVideo(row) {
      this.$router.push({
        path: '/video/list/' + row.channelId,
        query: {
          channelTitle: row.channel
            ? row.channel.title
            : row.channelId + '视频列表'
        }
      })
    },
    // 删除
    delData(row) {
      let that = this
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delChannel(row.id).then(res => {
            that.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.current = 1
            this.listData = []
            that.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    currentChange(val) {
      this.current = val
      this.getList()
    },

    selectStatus(data) {
      this.current = 1
      this.listData = []
      this.getList()
    },
    clearStatus() {
      this.current = 1
      this.listData = []
      this.getList()
    },
    getList() {
      this.loading = true
      this.scrollDisabled = true
      let obj = {}
      obj.status = this.statusValue
      channelPage(this.current, this.limit, obj).then(data => {
        this.loading = false
        this.total = data.all

        if (data.items.length > 0) {
          this.current++
          this.scrollDisabled = false
          this.listData = this.listData.concat(data.items)
          if (data.items.length < this.limit) {
            this.nomoreText = '没有更多了...'
          }
        } else {
          this.nomoreText = '没有更多了...'
        }
      })
    },
    addPlan() {
      if (this.searchForm.channelUrl.trim() == '') {
        this.$message({
          type: 'warning',
          message: '请输入链接！'
        })
        return
      }
      this.searchForm.channelUrl = this.searchForm.channelUrl.trim()

      setChannel(this.searchForm).then(res => {
        this.$message({
          type: 'success',
          message: '提交成功！'
        })
        this.searchForm.channelUrl = ''
        this.listData = []
        this.current = 1
        this.getList()
      })
    },
    specialCharacters(str) {
      if (!str) return true

      const specialKey =
        "[`~!#$^&*()=|{}': ; '\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。、？‘']"
      for (var i = 0; i < str.length; i++)
        if (specialKey.indexOf(str.substr(i, 1)) != -1) return false

      return true
    },
    // 复制链接
    copyClick(link) {
      const input = document.createElement('input') // 创建一个隐藏input（重要！）
      input.value = link // 赋值
      document.body.appendChild(input)
      input.select() // 选择对象
      if (document.execCommand('copy')) {
        document.execCommand('copy') // 执行浏览器复制命令
        this.$message({ message: '复制成功', type: 'success' })
      }
      document.body.removeChild(input)
    }
  }
}
</script>

<style lang="scss">
.channel-words {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  .channel-words-head {
    width: 100%;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 9px 0 rgb(82 63 105 / 18%);
    .el-form-item {
      margin: 10px 0;
      padding: 0 15px;
    }
    .head-info {
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
        vertical-align: middle;
      }
      span {
        font-size: 12px;
        font-weight: 300;
        color: #4b4b4b;
      }
    }
    .select-box {
      align-self: center;
      margin-right: 10px;
    }
  }
  .channel-words-content {
    width: 100%;
    height: calc(100% - 100px);
    margin-top: 5px;
    border-radius: 5px;
    background: white;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    .channel-words-content-child {
      width: calc(100% + 16px);

      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }
    li {
      list-style: none;
      text-align: center;
      color: #555555;
      font-size: 12px;
    }
  }
  //
  .content-item {
    width: calc(100% / 5 - 9px);
    height: 295px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 15px;
    margin-right: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    .content-item-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      .item-top-left {
        display: flex;
        width: calc(100% - 20px);
        overflow: hidden;
        > div {
          align-self: center;
        }
        img {
          vertical-align: middle;
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 50%;
        }
        .item-name-box {
          width: 100%;
          margin-left: 10px;
          white-space: nowrap;

          .item-name {
            font-size: 14px;
            font-weight: bold;
            color: #1a1a1b;
            overflow: hidden;
            white-space: nowrap;
            width: calc(100% - 40px);
            text-overflow: ellipsis;
          }
          .item-register {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 300;
            color: #1a1a1b;
            .modification {
              display: inline-block;
              width: 2px;
              height: 2px;
              background: #1a1a1b;
              border-radius: 50%;
              vertical-align: middle;
              margin: 0 5px;
            }
          }
        }
      }
      .item-top-right {
        img {
          width: 14px;
          height: 14px;
          vertical-align: middle;
        }
      }
    }
    .content-item-top::after {
      content: '';
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      position: absolute;

      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
    .content-item-key {
      margin-top: 20px;
      .content-item-channel-top {
        span {
          vertical-align: middle;
          margin-left: 5px;
          font-size: 12px;
          font-weight: 300;
          color: #1a1a1b;
        }
        img {
          vertical-align: middle;
        }
      }
      .content-item-channel-text {
        margin-top: 5px;
        // padding: 0 15px;
        border-bottom: 1px solid #e5e5e5;
        height: 43px;
        font-size: 12px;

        word-break: break-all;

        // text-overflow: ellipsis;
        // display: flex;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          cursor: pointer;
        }
        .website-text {
          display: inline-block;
          height: 33px;
          overflow: hidden;
          line-height: 16px;
        }
      }
      .content-item-channel-text2 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 25px;
      }
    }
    .content-item-channel-type {
      height: 30px;
      margin-top: 10px;
      //   padding: 10px 0;
      display: flex;
      img {
        width: 22px;
        height: 22px;
        object-fit: cover;
      }
      .type-info {
        font-size: 12px;
        font-weight: 300;
        color: #1a1a1b;
        margin-left: 5px;
        word-break: break-all;
        padding-bottom: 5px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
    }
    .content-item-time {
      font-size: 12px;
      padding-top: 13px;
      font-weight: 300;
      color: #1a1a1b;
      white-space: nowrap;
    }
    .channel-status2 {
      width: 100%;
      position: absolute;
      top: calc(50% + 70px);
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img {
        width: 30%;
        height: auto;
      }
      .status2-text {
        margin-top: 10px;
        width: 100%;
        color: #707070;
        white-space: nowrap;
      }
    }
  }
}
.keywords-popover {
  width: 80px !important;
  min-width: 50px !important;
  padding: 5px;
}
.channel-pagination {
  margin: 5px 0;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  font-size: 12px;
  color: #4b4b4b;
  background: #ffffff;
  border-radius: 5px;
}
</style>
